import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    debugger;
    this.updateUrl = this.element.dataset.updateUrl;
    this.loader    = this.element.querySelector('[data-activation="loader"]');
  }

  async toggle(event) {
    this.loader.classList.remove('opacity-0');
    this.loader.classList.add('opacity-100');

    const response = await fetch(this.updateUrl, {
      method: 'PATCH',
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": this.getMetaValue("csrf-token")
      },
      body: JSON.stringify({ active: event.target.checked })
    });

    if (!response.ok) {
      event.target.checked = !event.target.checked;
    }

    this.loader.classList.remove('opacity-100');
    this.loader.classList.add('opacity-0');
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
