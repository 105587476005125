import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() { }

  purchase() {
    Paddle.Checkout.open({
      product: this.productId,
      items: [{ priceId: this.priceId, quantity: 1 }],
      customer: this.customerData,
      customData: this.customData,
    });
  }

  cancelSubscription(event) {
    event.preventDefault();
    Paddle.Retain.initCancellationFlow({ subscriptionId: event.target.dataset.subscriptionId });
  }
}
